/* Estilizações do Modal Grade/Pack */
.pack-items p {
    color: #510C76;
    font-weight: bold;
    padding: 2px 12px;
    border: 1px solid #510C76;
    border-radius: 50%;
    margin-right: 18px;
}

.pack-items p:nth-child(4) {
    margin-left: 4px;
}

.pack-items p:nth-child(5) {
    margin-left: 12px;
}

.pack-items p:last-child {
    margin-left: 16px;
}

/* Estilizações do Modal de Referência */
.modalReference {
    display: flex;
    justify-content: center;
}

.modalReferenceContent {
    margin-right: 16px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.modalReferenceInfo {
    display: flex;
    justify-content: space-between;
    width: 420px;
    padding-right: 16px;
}

.text-area {
    width: 400px;
    height: 180px !important;
    background-color: #e5e5e5 !important;
    border: none;
}

/* Estilizações das Tabs */

.data-title {
    font-weight: bold;
    color: rgba(0,0,0,0.8);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    width: 10vw;
}

.data-title p {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8px 16px 8px 0px;
    width: 20vw;
}

.data-value {
    width: 60vw;
}

.data-value p {
    padding: 8px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.table_value-product {
    display: flex;
    align-items: center;
    padding: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.table-title {
    font-weight: bold;
    margin-right: 16px;
}

.classification {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8px 0px 16px 0px;
}

.barcode {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8px 0px 16px 0px;
}

.photo-list img {
    width: 72px;
    border-radius: 8px;
}

.photo-list .photo-name {
    font-weight: bold;
}

.photo-description {
    color: rgba(0, 0, 0, 0.8)
}

.photo-list p:last-child {
    color: #510C76;
    font-weight: bold;
    cursor: pointer;
}