.row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.col-preview {
  margin-top: 2rem;

  .preview {
    width: 400px;
    height: 400px;
    background-color: #F3F3F3;
    display: flex;
    align-items: center;
    justify-content: center;

    span.anticon {
      font-size: 2rem;
      color: #777777;
    }
  }
}

.button-col {
  margin-top: 2rem;
  width: 100%;

  button {
    width: 100%;
  }
}
