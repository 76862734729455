.drag-item-hover{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px;    
}

.drag-item-hover-inner{
    width: 100%;
    height: 100%;
    color: white;
    font-size: 25px;
    display: none;
}

.drag-item-hover:hover{
    background-color: rgba(0, 0, 0, 0.6);
}

.drag-item-hover:hover .drag-item-hover-inner{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.close-icon{
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    background-color: white;
    border-radius: 50%;
}

.add-image-icon{
    margin: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #512874;
    border-radius: 50%;
    font-size: 30px;
}

.checkbox {
    display: inline-block;
    vertical-align: middle;
}

.checkbox input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.checkbox label {
    position: relative;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox label::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 18px;
    width: 18px;
    background: #FFF;
    border: 2px solid #512874;
    transition: all 0.2s ease;
    margin-right: 5px;
    border-radius: 50%;
}

.checkbox label::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0.67em;
    transform: translate(-50%, -50%);
    font-size: 1em;
    border: 3px solid #FFF;
    opacity: 0;
    transition: all 0.2s ease;
    width: 14px;
    height: 14px;
    border-radius: 50%;
}

.checkbox label:focus::before, .checkbox label:hover::before {
    background: #DDD;
}

.checkbox input:checked + label::before {
    background: #512874;
    border-color: #512874;
}

.checkbox input:checked + label::after {
    opacity: 1;
}