.panel {
    color: #fff !important;
    background-color: #bbb;
}

.panel-item {
    padding: 12px;
    border-radius: 4px;
}

.panel-item:hover {
    cursor: pointer;
    color: #ffffff !important;
    background-color: #510c76;
    transition: 1s;
}
