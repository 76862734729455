.cover-col {
  display: flex;
  align-items: center;
  gap: 25px;
}

.upload-btn:hover {
  color: #381E67;
  border-color: #381E67;
}

.change-btn {
  color: #381e67;

  span {
    text-decoration: underline !important;

  }
}
