.icon-search {
  color: var(--support-color);
  border: var(--support-color) 1px solid;
  border-radius: 10px;
  font-size: 5rem;
  align-self: center;
  padding: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.content-container {
  display: flex;
  flex-direction: column;
}