.has-divider-after:after, .has-divider-before:before {
    content: '';
    position: absolute;
    border-left: 1px solid gray;    
    height: 80%; 
}

.has-divider-after:after {
    right: -10px; 
}

.has-divider-before:before {
    left: -10px;
}

[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px;
  border-radius: 2px;
}

.table-header .ant-table thead > tr > th {
    background-color: #f5f5f5;
    color: black
}