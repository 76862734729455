.reference-row {
    margin: 2vh 1vw;
    align-items: center;
    text-align: center;
    align-self: center;
    justify-content: center;
    border: 2px var(--primary-color) solid;
    border-radius: 5px;
    max-width: 90%;
    width: 700px;
}

.container-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 5px;
    padding: 5px;
    margin: 0px 25px;
}

.skeleton-title {
    margin-top: 15px;
}

.image-skeleton {
    margin: 5px;
}

.image-size {
    min-width: 125px !important;
    min-height: 125px !important;
    width: 10vw !important;
    height: 10vw !important;
}

.image-item {
    border-radius: 10px;
    object-fit: cover;
    padding: 5px;
}

.container-one-image {
    display: flex;
    flex-direction: column;
    align-items: center;
}