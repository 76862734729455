.radio-option {
    font-size: 18px;
    min-width: 180px;
    margin-top: 8px;
}

.question-panel-wrapper {
    flex-direction: column;
    align-items: center;
    background: white;
    border-radius: 6px;
    padding: 20px;
    height: 200px;
    width: 100%;
}