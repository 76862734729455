.payment-table-h1 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}

thead[class*="ant-table-thead"] th {
  background-color: #666666;
  color: var(--support-color);
  font-size: 16px;

  transition: filter 0.2;
}

.payment-table .ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 16px;
}

.payment-table .ant-table-container table > thead > tr:last-child th:last-child {
  border-top-right-radius: 16px;
}
