.collaborators {
  width: 98%;
}

.collaborators-modal {
  width: 100% !important;

  display: flex;
  align-items: center;
}

.collaborators-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
}

.collaborators-ant-modal .ant-modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.collaborators-right-content-header {
  display: flex;
}

.collaborators-input-content {
  margin-right: 24px;

  display: flex;
}

.collaborators-input-content input, .collaborators-input-content button, .collaborators-button {
  height: 32px;
}

.collaborators-button {
  background: none;
  border: none;
  color: #510c76;

  display: flex;
  align-items: center;
  cursor: pointer;
}

.collaborators-button span {
  border: none;
  color: #510c76;
  margin-right: 8px;
  padding: 2px;
}

.collaborators-button svg {
  color: #ffffff;
  background: #510c76;
  border-radius: 50%;

  font-size: 16px;
  font-weight: bold;

  padding: 1px;
}

.collaborators-search-button {
  width: 32px;
  background: #ffffff;


  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-bottom: 2px solid #510c76;

  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.collaborators-search-button:hover {
  background-color: #510c76;
  color: #ffffff;
}

.collaborators-search-button svg {
  font-size: 20px;
}

.collaborators-input-content input {
  border: none;
  width: 400px;
  border-bottom: 2px solid #510c76;

  padding: 0 8px;
}

.collaborators-dropdown-menu {
  background-color: #3b3b3b;
  border-radius: 4px 0 4px 4px;

  position: relative;
  right: 0;
  width: 180px;
}

.collaborators-dropdown-menu-item-button {
  background: none;
  border: none;
  cursor: pointer;

  color: #ffffff;
  font-size: 14px;

  transition: color 0.2s;
}

.collaborators-dropdown-menu-item:hover .collaborators-dropdown-menu-item-button {
  color: #ce74ff;
}

.collaborators-dropdown-menu-item:hover {
  background: #3b3b3b;
}

.collaborators-modal .ant-modal-content {
  border-radius: 8px;
  width: 440px;
  height: 440px;


  margin: 0 auto;
}

.collaborators-modal .ant-row  {
  width: 100%;
}

.collaborators-modal .ant-form  {
  width: 85%;
  margin: 0 auto;
}


.collaborators-modal .ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  height: 100%;
}

.collaborators-modal button.ant-modal-close, .collaborators-modal button.ant-modal-close svg  {
  font-size: 20px;

}
