.reference-detail-information-container {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
}

.reference-detail-column {
    display: flex;
    background: white;
    flex-direction: column;
    min-height: 320px;
}

.text-area::-webkit-scrollbar {
    width: 8px;
    background: #f5f5f5;
    border-radius: 8px;
}

.text-area::-webkit-scrollbar-thumb {
    -webkit-appearance: none;
    background: var(--primary-color);
    border-radius: 8px;
}