input.collaborator-first-step-inputs {
  border: none;
  border-bottom: 2px solid #510c76;

  border-radius: 4px;

  background: #f5f5f5;

  height: 48px;
  padding: 0 12px;
  margin-bottom: 8px;
}

input.collaborator-first-step-inputs::placeholder {
  color: #666666;
}

.collaborators-step .ant-steps-item-title {
  font-size: 12px;

  padding-bottom: 8px;
}
