.strong-price-table {
    font-size: 16px;
    color: #555;
    padding: 8px;

}

.strong-price-table:hover {
    color: rgb(177, 34, 34);
    cursor: pointer;
    background-color: #f5f5f5;
    border-radius: 6px;
}

.descriptions-price-table {
    color: var(--primary-color);
    cursor: pointer;
    font-weight: bold;
    padding: 8px;
}

.descriptions-price-table:hover { 
    background-color: #f5f5f5;
    border-radius: 6px;
}
