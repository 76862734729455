input {
    height: 48px;
    border-radius: 8px;
}

.ant-select-selector{
    height: 48px !important;
    border-radius: 8px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: start !important;
}